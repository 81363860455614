import {fetch} from '../index';
import {QueryMethod} from '../utils';
import {EmployeeVoucherLinkDetailsDto} from './dto';
import {AddressInformationRequest} from '../../views/account-settings/requestObjects/intex';
import {RetailerDtoWithShopsAndFeedAuthentication} from '../retailer/dto';

export async function getEmployeeVoucherLinkDetails() {
    return await fetch<EmployeeVoucherLinkDetailsDto>('/gogeta-api/v1/user-employee-settings/voucher-link-details', QueryMethod.GET);
}

export async function getEmployeeAddressInformationSettings() {
    return await fetch<AddressInformationRequest>('/gogeta-api/v1/user-employee-settings/information', QueryMethod.GET);
}

export async function getRetailerInformationSettings() {
    return await fetch<RetailerDtoWithShopsAndFeedAuthentication>('/gogeta-api/v1/retailer/retailer-by-user', QueryMethod.GET);
}
