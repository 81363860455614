import { ScCookieButtonsWrapper, ScCookieWrapper } from './styled';
import { useState, useEffect } from 'react';
import { ReactComponent as Cookie } from 'src/assets/images/icons/Cookie.svg';
import { ReactComponent as Icon } from 'src/assets/images/icons/button-arrow.svg';

export const CookieComponent = () => {
  const [cookieAccepted, setCookieAccepted] = useState(true);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) {
      setCookieAccepted(true);
    } else {
      setCookieAccepted(false);
    }
  }, []);

  const handleAcceptCookie = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setCookieAccepted(true);
  };

  const handleRejectCookie = () => {
    localStorage.setItem('cookieAccepted', 'false');
    setCookieAccepted(true);
  };

  const cookieLink = `${process.env.REACT_APP_LANDINGS_URL}/cookie-policy`
  const privacyPolicyLink = `${process.env.REACT_APP_LANDINGS_URL}/privacy-policy`

  return (
    <ScCookieWrapper isOpen={!cookieAccepted}>
      <Cookie />
      <p>
        We use cookies on this site to enhance your experience. For a complete overview of all
        cookies used please see our <a href={cookieLink}>cookie policy</a>. Your privacy is
        important to us and we will never sell your data.{' '}
        <a href={privacyPolicyLink}>Privacy policy</a>.
      </p>
      <ScCookieButtonsWrapper>
        <button onClick={handleRejectCookie}>
          Reject cookies
          <Icon />
        </button>
        <button onClick={handleAcceptCookie}>
          Accept cookies
          <Icon />
        </button>
      </ScCookieButtonsWrapper>
    </ScCookieWrapper>
  );
};
