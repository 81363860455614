import * as Sentry from '@sentry/react';
import { IS_DEV_CUSTOM, IS_PROD } from '../utils/config';

if (IS_DEV_CUSTOM) {
  Sentry.init({
    dsn: "https://a5cdef6b8a9f39e9fbc40a4c9624a90f@o4508811248271360.ingest.de.sentry.io/4508811258495056",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/dev.gogeta\.bike\/gogeta-api\/v1/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else if (IS_PROD) {
  Sentry.init({
    dsn: "https://d1163aefd4d7db18dbc6dda55bfc671e@o4508811261116416.ingest.de.sentry.io/4508811262230608",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/gogeta\.bike\/gogeta-api\/v1/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}