/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { createContext, ReactNode } from 'react';
import { ErrorResponse, ListEntityResponse } from '../../requests';
import { findAllNotifications, markNotificationAsRead } from '../../requests/notification';
import { NotificationDto } from '../../requests/notification/dto';
import useAuth from 'src/guards/authGuard/UseAuth';
import { FormattedNotification } from './types';
import { formatNotificationDetails } from './utils';
import { getPortalType } from 'src/guards/jwt/utils';
import { useLocation } from 'react-router-dom';
import { excludeArrays } from 'src/guards/authGuard/GuestGaurd';

interface ContextProviderProps {
  children: ReactNode;
}

export interface NotificationsCtx {
  list: FormattedNotification[];
  markAsRead: (id: number) => void;
}

const defaultState: NotificationsCtx = {
  list: [],
  markAsRead: (id: number) => Promise.resolve(),
};

const NotificationsCtx = createContext<NotificationsCtx>(defaultState);

export const useNotificationsCtx = () => React.useContext(NotificationsCtx);

export const NotificationsProvider = ({ children }: ContextProviderProps) => {
  const { isAuthenticated, isEmployee, isAdmin, isEmployer, isNotAdmin, isRetailer, isSuperAdmin } =
    useAuth();

  const location = useLocation();

  const isInPortal =
    getPortalType(window.location.pathname) !== undefined &&
    !excludeArrays.includes(location.pathname);

  const queryResult = useQuery<ListEntityResponse<NotificationDto>, ErrorResponse>({
    queryKey: ['notifications'],
    queryFn: findAllNotifications,
    retry: false,
    enabled: !!isInPortal && (isAuthenticated || isAdmin),
    refetchInterval: 10000,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await queryResult.refetch();
    };
    isInPortal && (isAuthenticated || isAdmin) && fetchData();
  }, [isEmployer, isNotAdmin, isAdmin, isRetailer, isSuperAdmin, isEmployee]);

  const markAsReadMutation = useMutation({
    mutationFn: markNotificationAsRead,
    onSuccess: async () => {
      await queryResult.refetch();
    },
  });

  const markAsRead = (id: number) => markAsReadMutation.mutate(id);

  return (
    <NotificationsCtx.Provider
      value={{
        markAsRead,
        list:
          !isAuthenticated && !isAdmin
            ? []
            : (queryResult.data?.data ?? []).map(formatNotificationDetails),
      }}
    >
      {children}
    </NotificationsCtx.Provider>
  );
};
